var prodcat = prodcat || {};

(function($) {
  // @todo make these more dynamic, maybe come in from drupal settings.
  var gridWrapperClass = '.js-product-grid';
  var gridItemClass = '.js-product-grid-item';
  var gridBypassReorderClass = 'js-product-grid--bypass-reorder';

  // @todo this could be customizeable per brand as well.
  // Statuses that trigger reorder.
  var reorderStatuses = [
    // 2, // Temporarily Sold Out
    // 3, // Coming Soon
    7 // Sold Out
  ];

  $(document).on('product.updateInvStatus', '.js-product-ui', function() {
    var $updatedProduct = $(this);
    var $gridWrapper = $updatedProduct.closest(gridWrapperClass);
    var skuBaseId = $updatedProduct.data('sku-base-id');
    var skuDataL2 = prodcat.data.getSku(skuBaseId);

    // Validate grid wrapper.
    if (!skuDataL2 || !$gridWrapper.length || $gridWrapper.hasClass(gridBypassReorderClass)) {
      return;
    }

    // Returns boolean of whether we should shuffle element.
    var shouldReorder = function(l2Cache) {
      // return l2Cache.SKU_BASE_ID === 96814;
      return _.includes(reorderStatuses, parseInt(l2Cache.INVENTORY_STATUS));
    };

    // Takes current element and moves it to the end of the grid.
    var reorderGridItem = function($el) {
      var $gridItem = $el.closest(gridItemClass);
      var $carousel = $el.closest('.slick-slider');
      if (!$gridItem.length) {
        return;
      }

      // If slick carousel, use their API, else do it the old fashioned way.
      if ($carousel.length) {
        // The jQuery index may be offset by the slick cloned items so use slick's index attribute if available
        var index = $gridItem.attr('index') || $gridItem.index();
        $carousel.slickRemove(index).slickAdd($gridItem);
      } else {
        $gridItem.appendTo($gridItem.parent());
      }
    };

    // Reordering sold out products (inv. status 7), to be last in display order on MPPs/anywhere they appear in a grid
    if (shouldReorder(skuDataL2)) {
      reorderGridItem($updatedProduct);
    }
  });
})(jQuery);
